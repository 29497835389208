import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ErrorIcon from '../assets/imgs/fi_alert-triangle.svg'
import Services from "../services/global.service"
const FormSupplier = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    clientName: Yup.string().required('Client name is required'),
    telephone: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Phone number is required'),
    comment: Yup.string(),
    deliveryDateTime: Yup.string().required('Delivery date and time are required'),
    deliveryReceipt: Yup.mixed().required('Delivery receipt PDF is required').test(
      'fileSize',
      'File too large',
      value => value && value.size <= 25000000
    ),
  });
  const [address, setAddress] = useState("");
  const [file, setFile] = useState(null);
  const [Error, setError] = useState("");
  const [latLong, setLatLong] = useState(null);
  const handleSubmit = (values, { setSubmitting }) => {
    // Handle form submission here, e.g., send email or perform API call
    console.log('values',values);
    // Reset form after submission
    setSubmitting(false);
  };
  const HandleSaveAdr = (values) => {
    // Handle saving address logic here
    console.log(values,file,address); // This will log the form values when Save is clicked
    const data = {
      latitude: latLong?.lat,
      longitude: latLong?.lng,
    };
    if(!address || !latLong?.lat){
      setError('Adresse requis') ;
      return;
    }
    //console.log("data", data);
    // if(address){
    //    Services.create(data, "zones/adresse")
    //   .then((res) => {
    //     //console.log(res.data);
    //     const adr={
    //         ...data,
    //         ...values,
    //         adresse:address,
    //         type:1,
    //         user:localStorage.getItem('id')
    //     }
    //    // console.log(adr)
    //     if(res.data){
    //         Services.create(adr,'adresses/create')
    //         .then((res)=>{
    //            // console.log(res.data)
    //             Services.getAll(`addresses/${localStorage.getItem('id')}/update-default-livraison-address/${res.data.adresse.id}`)
    //             .then((res)=>{
    //               console.log(res)
                  
    //             })
    //             .catch((err)=>{console.log(err)})
                
            
    //         })
    //         .catch((err)=>console.log(err))
    //     }
    //     else{
    //       setError('Adresse non prise en charge')
    //     }
    //     // setCheck(res.data)
    //     //add adresse set localstorage adr
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // }

  };
  const handleChangeAddress = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    setAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;

      // Extract necessary address components
      const postalCode = addressComponents.find(component => 
        component.types.includes('postal_code')
      );
      const street = addressComponents.find(component => 
        component.types.includes('route')
      );
      if (postalCode && street) {
        console.log('Address is complete:', postalCode.long_name, street.long_name);
  
        const latLng = await getLatLng(results[0]);
      if (latLng) {
        setLatLong(latLng);
      } else {
        console.error("Error: Unable to get latitude and longitude");
      }
    }else {
      console.error('Error: Incomplete address. Missing postal code or street.');
    }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
    types: ['address']
  }
  return (
    <div>
      <h2>Commande fournisseur</h2>
      <Formik
          initialValues={{
            email: "",
            clientName: "",
            tel: "",
            comment: "",
            deliveryDateTime:"",
             deliveryNote: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => HandleSaveAdr(values)}
        >
          {({ errors,handleSubmit,touched,values,setFieldValue  }) => (
            <Form onSubmit={handleSubmit} className="mt-40">
            
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      id="floatingInputemail"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputemail">
                     Adresse e-mail
                    </label>
                  </div>
                </div> 
                <div className="col-md-6"></div>
              </div>
              <div className="row mt-16">
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Autocomplete
                      value={address}
                      className=""
                       searchOptions={searchOptions}

                      onChange={handleChangeAddress}
                      onSelect={handleSelect}
                      onPlaceSelected={(place) => {
                      //  console.log(place);
                      }}
                      
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="form-floating mb-3">
                           <input
                    {...getInputProps({
                      placeholder: "",
                      className:`form-control ${ Error!==""  ? "danger-input" : ""}`
                    })}
                    id="floatingAdr"
                  />
                    <label htmlFor="floatingAdr">Adresse</label>
                          <div
                            className={
                              suggestions.length > 0 && `container-autocomplete`
                            }
                          >
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41B6E6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  className="mapItem"
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Autocomplete>
                    <div className="text-danger-custom">{Error}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="clientName"
                      className={`form-control ${touched.clientName && errors.clientName ? "danger-input" : ""}`}
                      id="floatingInputClientName"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputClientName">
                      Nom client / entreprise
                    </label>
                  </div>
                  {touched.clientName && errors.clientName && <div className="text-start mt-8 d-flex">
                     <img src={ErrorIcon} className="mr-8" alt="error icon"/>
                   <span className="text-danger-custom">{errors.clientName}</span></div>}
                </div>
              </div>
              <div className="row mt-16">
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="telephone"
                      className={`form-control ${touched.telephone && errors.telephone ? "danger-input" : ""}`}
                      id="floatingInputTelephone"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputTelephone">
                      Numéro de téléphone
                    </label>
                  </div>
                  {touched.telephone && errors.telephone && <div className="text-start mt-8 d-flex">
                     <img src={ErrorIcon} className="mr-8" alt="error icon"/>
                   <span className="text-danger-custom">{errors.telephone}</span></div>}
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="form-floating mb-3">
                    <Field
                      type="text"
                      name="comment"
                      className={`form-control ${touched.comment && errors.comment ? "danger-input" : ""}`}
                      id="floatingInputcomment"
                      placeholder=""
                    />
                    <label htmlFor="floatingInputcomment">
                      Commentaire
                    </label>
                  </div>
                  {touched.comment && errors.comment && <div className="text-start mt-8 d-flex">
                     <img src={ErrorIcon} className="mr-8" alt="error icon"/>
                   <span className="text-danger-custom">{errors.comment}</span></div>}
                </div>
              </div>
                <div className="row mt-16"> 
                    <div className="col-md-6">
                     
                      <Field type="date" name="deliveryDateTime"  id="floatingInputdeliveryDateTime" placeholder="" 
                      className={`form-control ${touched.deliveryDateTime && errors.deliveryDateTime ? "danger-input" : ""}`} />
                    {/* <label htmlFor="floatingInputdeliveryDateTime">
                     Date livraison souhaité
                    </label> */}
                    {errors.deliveryDateTime && touched.deliveryDateTime && <div>{errors.deliveryDateTime}</div>}
                    </div>
                    <div className="col-md-6">
                        <Field type="file" name="deliveryReceipt"  onChange={(event) => {
                        setFile( event.currentTarget.files[0]);
                         }} />
              {errors.deliveryReceipt && touched.deliveryReceipt && <div>{errors.deliveryReceipt}</div>}</div>
                   
                </div>
              <div className="text-end mt-24">
                <button type="submit" className="cta-primary-m" onClick={HandleSaveAdr}>
                  Envoyer
                </button>
              </div>
            </Form>
          )}
        </Formik>
    </div>
  );
};

export default FormSupplier;
