import { useEffect, useState } from "react";
import Services from "../services/global.service";
import ArrowRight from "../assets/imgs/fi_arrow-down.svg";
const NosMarques = () => {
  const [marques, setMarques] = useState([]);
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;

  const getMarques = () => {
    Services.getAll("marques")
      .then((res) => {
        console.log(res.data);
        // Filtre les éléments de res.data dont la propriété image commence par "marques/" et n'est pas vide
      const filteredData = res.data.filter(el =>  el?.image !== 'marques/' && el?.image!=="" && el?.image!==null);

    // Utilise les données filtrées
    setMarques(filteredData);

      })
      .catch((err) => {
        console.error("Error fetching marques:", err);
      });
  };

  useEffect(() => {
    getMarques();
  }, []);

  return (
    <div className="container-brands">
      <h5 className="">Nos marques</h5>
      <div className="brands mt-40 row">
        <div className="brand-row">
          {marques.slice(0,24).map((data, index) => (
            <div className="col" key={index}>
              <img src={IMG_URL + data?.image} alt="marque img" />
            </div>
          ))}
        </div>
        <div className="text-end mt-32"><a href="/marques" >Tout voir <img src={ArrowRight} alt="voir details icon" /></a></div>
        
      </div>
    </div>
  );
};

export default NosMarques;
