import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Eye from '../assets/imgs/fi_eye.svg'
import EyeClosed from '../assets/imgs/fi_eye-off.svg'
import ArrowRight from '../assets/imgs/fi_arrow-right.svg'
import AuthService from "../services/auth.service";
import { useMyContext } from "../context";
import Services from "../services/global.service";

const FormLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [myAddress, setMyAddress] = useState();
  const location = useLocation();
  const redirectUrl = localStorage.getItem('redirectAfterLogin');
  const { state } = location;



  const navigate = useNavigate();
  const { setUserData } = useMyContext(); 
  const handleToggle = () => {
    setShowPassword(!showPassword);
  };
  const getMyAdresses=()=>{
    Services.getOne(localStorage.getItem('id'),'adresses')
        .then((res)=>{console.log(res);setMyAddress(res.data.filter(address => address.name === 'default'));
        console.log('aaaa',myAddress)
        if(myAddress){
          localStorage.setItem('adr',myAddress[0]?.adresse)
        }
      
      }
        )
        .catch((err)=>{console.log(err)})
        
}

  const handleSubmit = (event) => {
    event.preventDefault();
   console.log("içi test de redirection :",state)
    AuthService.login(email, password).then(
      (response) => {
        setUserData(response?.data?.user);
          const from = state && state.from;
           // Redirige vers l'URL récupérée depuis le localStorage ou vers une URL par défaut
            if (redirectUrl) {   
             window.location.href = redirectUrl;    
            }            
          // if(from==="details"){
          //  // navigate(-1);
          // //  window.history.back();
          // window.location.href = `/product/${state.product}`;
          // }     
        else if(localStorage.getItem('token') && localStorage.getItem('role')!==5){
          console.log('hereeee',localStorage.getItem('token'))
          getMyAdresses(localStorage.getItem('token'))
          window.location.href = '/';
        }
        if(localStorage.getItem('role')==5){
          window.location.href = 'https://recette.bo.marion-materiaux.com/login';
        }
        
        
      },
      (error) => {
        if (error.response && error.response.status === 400) {
          console.log("Le mot de passe saisi est invalide, veuillez réessayer", error.response.data.message);
          setError(error.response.data.message);
          return;
        }
        if (error.response && error.response.status === 404) {
          console.log("Email saisi est invalide, veuillez réessayer");
          setError("User not found");
          return;
        }
        console.log("err", error);

        if (error.response && error.response.status === 401) {
          setError("Le mot de passe saisi est invalide, veuillez réessayer");
          return;
        }
        else{
            setError("Désolé, une erreur est survenue.");
        }
      
      }
    );
  };

  return (
    <div className="login">
      <div className="form-login">
        <form onSubmit={handleSubmit}>
          
             <div className='mt-32'>
                    <div className="form-floating mb-3">
                        <input 
                        type="email" 
                        name="email"  
                        onChange={(event) => {
                          setEmail(event.target.value);
                          setError("");
                        }} 
                        className="form-control"
                         id="floatingInput" placeholder=""
                         />
                        <label for="floatingInput">Adresse e-mail</label>
                    </div>
                    
                        
                    </div>
        
            <div className=" password-input-container form-floating mb-3 flex-grow-1">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder=""
                onChange={(event) => {
                  setPassword(event.target.value);
                  setError("");
                }}
                className="form-control"
              />
              <label htmlFor="password">Mot de passe</label>
              <div className="password-toggle-icon">
              {showPassword ? (
                <img src={Eye} className="password-icon" alt="password show" onClick={handleToggle} />
              ) : (
                <img src={EyeClosed} className="password-icon" alt="password-hide" onClick={handleToggle} />
              )}
            </div>
            </div>
            
       

          <div className="mt-8 text-end ">
              <a href="/Renitialise-mot-de-passe">Mot de passe oublié ?</a>
          </div>
          <div>
            <button type="submit" className=" cta-primary-m mt-24 w-100">
            Je me connecte <img src={ArrowRight} className="ml-8" alt ="arrow right"/>
            </button>
          </div>
          <span className="text-danger pt-5">{error}</span>
        </form>
      </div>
    </div>
  );
};

export default FormLogin;
